import React from "react";
import { Link } from 'react-router-dom';
import "../../styles/FeaturedProducts.css";

export default function CardProduct({ productos, busqueda, setBusqueda, categoria }) {

  const filteredProducts = productos?.filter((producto) => {
    return producto.nombre.toLowerCase().includes(busqueda.toLowerCase());
  });

  return (
    <>
      <div className="centerBanner">
        <img className='banner-products' src='https://i.ibb.co/V2Wj6h2/Banner-de-Linked-In-Tecnolog-a-Abstracto-Azul-y-Blanco.png' alt='' />
      </div>

        {/* Barra de Navegación */}
        <div className="breadcrumb-container">
        <nav className="breadcrumb-nav">
          <Link to="/productos" className="breadcrumb-link">
            Todos los productos
          </Link>
          {categoria && (
            <>
              {" "} &gt;
              <span className="breadcrumb-current">{categoria}</span>
            </>
          )}
        </nav>
      </div>
      <input onChange={(e) => setBusqueda(e.target.value)} type="text" className="form-control" placeholder="Busca tu producto" aria-label="Search" aria-describedby="basic-addon1" />

      <div className="container-featured-products">
        {filteredProducts?.map((producto) => ( producto &&
          <div className="cardProducts" key={producto.id}>
            <Link className="btn-prod-dec" to={"/item/" + producto.id}>
              <img className="img-card-product" src={producto.fotos[0]} alt="" />
              <div className="category-product">{producto.category}</div>
              <div className="title-product">{producto.nombre}</div>
              {/* <div className="colors-container">
                <div className="title-colors">Colores Disponibles</div>
                <div className="colors">
                  {producto.colores.map((color) => (
                    <div className="colores-disp" style={{ backgroundColor: color }}>

                    </div>
                  ))
                  }
                  </div>
                </div> */}
                <div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}
