import React from 'react'
import { createContext, useEffect, useState } from 'react';
import {getDocs, getFirestore, collection} from 'firebase/firestore';

export const FireContext = createContext();

const { Provider } = FireContext

const MyProvider = ({ children }) => {

    const [loading, setLoading] = useState(true);
    const [filminas, setFilminas] = useState();
    const [productos, setProductos] = useState();
    const [recetas, setRecetas] = useState();
  
    const colectionFilFire = "carrousel";
    const colectionProdFire = "productos";
    const colectionRecetas = "recetas";
    const db = getFirestore();
    const filminasColeccion = collection(db, colectionFilFire);
    const productosColeccion = collection(db, colectionProdFire);
    const recetasColeccion = collection(db, colectionRecetas);
  
    useEffect(() => {
      getDocs(filminasColeccion).then((res) => {
        setFilminas(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      });
    }, []);

    useEffect(() => {
      getDocs(recetasColeccion).then((res) => {
        setRecetas(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      });
    }, []);
  
    useEffect(() => {
      getDocs(productosColeccion).then((res) => {
        setProductos(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        setLoading(false);
      });
    }, []);

    return <Provider value={{ filminas, productos, loading, recetas }} >{children}</Provider>
}

export default MyProvider


