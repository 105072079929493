import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import NavBar from "./components/NavBar.jsx";
import Footer from "./components/Footer.jsx";
import Home from "./components/Home.jsx";
import Cart from "./components/Cart.jsx";
import FixedContactButton from './components/FixedContactButton';
import MyProvider from "./context/FireContext.jsx";
import ProductList from "./components/ProductList.jsx";
import DetailProduct from "./components/DetailProduct.jsx";
import { CartProvider } from './components/componentesCart/CartContext'; // Asegúrate de importar el contexto

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import AboutMe from './components/AboutMe';
import JoinMyTeam from './components/JoinMyTeam';
import BuildRecipes from './components/componentesBuilders/BuildRecipes';
import Recipes from './components/Recipes';
import DetailRecipe from './components/DetailRecipe';
import AddProduct from './components/AddProduct.jsx';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyCj5-s5QQDYGClXgzW4U6nUnOTFc2P81rI",
    authDomain: "antonohiessen.firebaseapp.com",
    projectId: "antonohiessen",
    storageBucket: "antonohiessen.appspot.com",
    messagingSenderId: "729274634401",
    appId: "1:729274634401:web:e73dc230ed969f4ccb60e0",
    measurementId: "G-1JEL6FTS1X"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <div>
      <BrowserRouter>
      <CartProvider>
      <MyProvider>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/productos" element={<ProductList />} />
          <Route path="/category/:category" element={<ProductList />} />
          <Route path="/item/:itemId" element={<DetailProduct />} />
          {/* <Route path="/recetas/:recetaId" element={<DetailRecipe />} /> */}
          <Route path="/sobre-nosotros" element={<AboutMe />} />
          <Route path="/como-llegar" element={<JoinMyTeam />} />
          {/* <Route path='/crear-receta/' element={<BuildRecipes/>} /> */}
          {/* <Route path='/recetas/' element={<Recipes/>} /> */}
          <Route path='/cart/' element={<Cart/>} />
          <Route path='/nuevoproducto/' element={<AddProduct/>} />
        </Routes>
      <Footer />
      </MyProvider>
      </CartProvider>
      </BrowserRouter>
      <FixedContactButton />
    </div>
  );
}

export default App;
