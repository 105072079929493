import React, { useState, useEffect } from 'react';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import "../styles/AddProduct.css";

function AddProduct() {
    const [nombre, setName] = useState('');
    const [desc, setDescription] = useState(['']);
    const [destacado, setIsFeatured] = useState(false);
    const [fotos, setPhotos] = useState(['']);
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [user, setUser] = useState(null);

    const allowedEmail = "santiagocabral1990@gmail.com";  // Reemplaza con el email permitido

    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const db = getFirestore();
    const productsCollection = collection(db, 'productos');

    const handleSignIn = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                setUser(result.user);
            })
            .catch((error) => {
                console.error("Error al iniciar sesión con Google:", error);
            });
    };

    const handleSignOut = () => {
        signOut(auth)
            .then(() => setUser(null))
            .catch((error) => console.error("Error al cerrar sesión:", error));
    };

    const handleAddDescription = () => setDescription([...desc, '']);
    const handleAddPhoto = () => setPhotos([...fotos, '']);
    const handleRemoveDescription = (index) =>
        setDescription(desc.filter((_, i) => i !== index));
    const handleRemovePhoto = (index) =>
        setPhotos(fotos.filter((_, i) => i !== index));

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (nombre && category && desc.length > 0 && fotos.length > 0) {
            const newProduct = {
                nombre,
                desc,
                destacado,
                fotos,
                category,
                subCategory: subCategory || null,
            };
            await addDoc(productsCollection, newProduct);
            alert('Producto agregado correctamente');
            setName('');
            setDescription(['']);
            setIsFeatured(false);
            setPhotos(['']);
            setCategory('');
            setSubCategory('');
        } else {
            alert('Por favor completa todos los campos requeridos');
        }
    };

    useEffect(() => {
        // Comprueba si el usuario actual tiene permiso
        if (user && user.email !== allowedEmail) {
            alert("No tienes permiso para agregar productos.");
            handleSignOut();
        }
    }, [user]);

    return (
        <div className="add-product-container">
            {!user ? (
                <button onClick={handleSignIn} className="sign-in-btn">Iniciar sesión con Google</button>
            ) : (
                <>
                    <h2 className="title">Agregar Nuevo Producto</h2>
                    <button onClick={handleSignOut} className="sign-out-btn">Cerrar sesión</button>
                    <form onSubmit={handleSubmit} className="product-form">
                        <div className="form-group">
                            <label>Nombre del Producto</label>
                            <input
                                type="text"
                                className="form-input"
                                placeholder="Nombre del Producto"
                                value={nombre}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label>Descripción:</label>
                            {desc.map((descc, index) => (
                                <div key={index} className="description-group">
                                    <input
                                        type="text"
                                        className="form-input"
                                        value={descc}
                                        placeholder={`Descripción ${index + 1}`}
                                        onChange={(e) =>
                                            setDescription(
                                                desc.map((d, i) => (i === index ? e.target.value : d))
                                            )
                                        }
                                    />
                                    {desc.length > 1 && (
                                        <button
                                            type="button"
                                            className="remove-btn"
                                            onClick={() => handleRemoveDescription(index)}
                                        >
                                            Eliminar
                                        </button>
                                    )}
                                </div>
                            ))}
                            <button
                                type="button"
                                className="add-btn"
                                onClick={handleAddDescription}
                            >
                                Agregar Descripción
                            </button>
                        </div>

                        <div className="form-group">
                            <label>Fotos:</label>
                            {fotos.map((photo, index) => (
                                <div key={index} className="photo-group">
                                    <input
                                        type="text"
                                        className="form-input"
                                        value={photo}
                                        placeholder={`Foto ${index + 1}`}
                                        onChange={(e) =>
                                            setPhotos(
                                                fotos.map((p, i) => (i === index ? e.target.value : p))
                                            )
                                        }
                                    />
                                    {fotos.length > 1 && (
                                        <button
                                            type="button"
                                            className="remove-btn"
                                            onClick={() => handleRemovePhoto(index)}
                                        >
                                            Eliminar
                                        </button>
                                    )}
                                </div>
                            ))}
                            <button
                                type="button"
                                className="add-btn"
                                onClick={handleAddPhoto}
                            >
                                Agregar Foto
                            </button>
                        </div>

                        <div className="form-group">
                            <label>¿Destacado?</label>
                            <input
                                type="checkbox"
                                checked={destacado}
                                onChange={(e) => setIsFeatured(e.target.checked)}
                            />
                        </div>

                        <div className="form-group">
                            <label>Categoría</label>
                            <input
                                type="text"
                                className="form-input"
                                placeholder="Categoría"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label>Sub-Categoría (Opcional)</label>
                            <input
                                type="text"
                                className="form-input"
                                placeholder="Sub-Categoría"
                                value={subCategory}
                                onChange={(e) => setSubCategory(e.target.value)}
                            />
                        </div>

                        <button type="submit" className="submit-btn">
                            Agregar Producto
                        </button>
                    </form>
                </>
            )}
        </div>
    );
}

export default AddProduct;